import {
  ContextAdvancedFiltersType,
  ContextFilterType,
  ContextGameTypeFiltersType,
} from "../types/context";
import {
  DEFAULT_ACTIVE_STATUS,
  DEFAULT_GAMETYPE_STATUS,
  DEFAULT_HOME_AWAY,
  DEFAULT_ROOKIE_STATUS,
  DEFAULT_OVERTIME_STATUS,
  DEFAULT_QUARTER_HALF_STATUS,
  DEFAULT_RANKING_STATUS,
} from "../compounds/FastFilters/constants";
import { DEFAULT_SPAN_RANGE_OPTION } from "../pages/Nba/PlayerSpanFinder/constants";
import { DEFAULT_STREAK_RANGE_OPTION } from '../pages/Nba/PlayerStreakFinder/constants';
import { ModalChipType } from "../types/modal";


// eslint-disable-next-line import/prefer-default-export
export const initialAdvancedFilters: ContextAdvancedFiltersType = {
  player_age: {
    selected: false,
    value: '',
    equalityOption: '>=',
  },
  career_game_number: {
    selected: false,
    value: '',
    equalityOption: '>=',
  },
  season_game_number: {
    selected: false,
    value: '',
    equalityOption: '>=',
  },
  debut_game: false,
  ps_debut_game: false,
  seasons: {
    selected: false,
    value: '',
    equalityOption: '>=',
  },
  team_ap_rank: {
    selected: false,
    value: '',
    equalityOption: '>=',
  },
  opponent_ap_rank: {
    selected: false,
    value: '',
    equalityOption: '>=',
  },
  team_seed: {
    selected: false,
    value: '',
    equalityOption: '=',
  },
  opponent_seed: {
    selected: false,
    value: '',
    equalityOption: '=',
  },

  season_group: '',
  season_group_value: '',
  season_group_end_value: '',

  ncaa_tournament: false,
  third_place_game: false,
  play_in_round: false,
  round_of_64: false,
  round_of_32: false,
  sweet_sixteen: false,
  elite_eight: false,
  semi_finals: false,
  championship: false,
  conference_game: false,
};

export const initialGameTypeFilters: ContextGameTypeFiltersType = {
  reg: true,
  ps: false,
  pin: false,
  ist: false,
  as: false,
  fin: false,
  con: false,
  sem: false,
  qtr: false,
  rr: false,
  rd1: false,

  holiday_group: '',
  holiday_group_value: '',
  holiday_group_end_value: '',
  postseason_group: '',
  postseason_group_value: '',
  postseason_group_end_value: '',
  season_group: '',
  season_group_value: '',
  season_group_end_value: '',

  // NCAA-specifc filters, should be ignored otherwise
  ncaa_tournament: false,
  third_place_game: false,
  play_in_round: false,
  round_of_64: false,
  round_of_32: false,
  sweet_sixteen: false,
  elite_eight: false,
  semi_finals: false,
  championship: false,
  conference_game: false,
};

export const defaultGameTypeChips: ModalChipType[] = [
  {
    id: 'reg',
    label: 'Regular Season',
  }
];

export const defaultAdvancedFilterChips: ModalChipType[] = [];

export const ncaaMbbSortOverrides = {
  sortBy: '-game__date',
  sortByField: 'game__date',
  sortByDirection: '-',
};

export const initialFilters: ContextFilterType = {
  searchEnabled: false,
  groupBy: 'none',
  streakRangeOption: DEFAULT_STREAK_RANGE_OPTION,
  spanRangeOption: DEFAULT_SPAN_RANGE_OPTION,
  sortBy: '-game__date',
  sortByField: 'game__date',
  sortByDirection: '-',
  allowSecondaryDefaultSort: false,
  page: 0,
  activeStatus: DEFAULT_ACTIVE_STATUS,
  rookieStatus: DEFAULT_ROOKIE_STATUS,
  homeAway: DEFAULT_HOME_AWAY,
  overtimeStatus: DEFAULT_OVERTIME_STATUS,
  halfQuarterStatus: DEFAULT_QUARTER_HALF_STATUS,
  rankingStatus: DEFAULT_RANKING_STATUS,
  oppRankingStatus: DEFAULT_RANKING_STATUS,
  gameTypeStatus: DEFAULT_GAMETYPE_STATUS,
  playerObject: null,
  opposingPlayerObject: null,
  playerTeammates: [],
  venueObject: null,
  teamObject: null,
  conferenceObject: null,
  opponentConferenceObject: null,
  opponentObject: null,
  streakGameNumber: '',
  spanLength: '',
  selectedStatsFilters: [],
  statsFilterChips: [],
  gameTypeFilters: {...initialGameTypeFilters},
  gameTypeFiltersChips: defaultGameTypeChips,
  advancedFilters: {...initialAdvancedFilters},
  advancedFiltersChips: defaultAdvancedFilterChips,
};

export const initialWnbaFilters: ContextFilterType = {
  ...initialFilters,
  gameTypeFilters: {
    ...initialGameTypeFilters,
    reg: false,
    ps: true,
  },
  gameTypeFiltersChips: [
    {
      id: 'ps',
      label: 'Postseason',
    },
  ],
};
