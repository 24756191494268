export const AE2_URL = process.env.REACT_APP_AE2_URL;

export const SPORT_LEAGUES = {
  NBA: 'NBA',
  NHL: 'NHL',
  MLB: 'MLB',
  NFL: 'NFL',
  WNBA: 'WNBA',
};

export const FEATURE_TOGGLES = {
  teamPages: true,
  playerPages: true,
  gamePages: true,
}

export const DEBOUNCE_DELAY = 350;

export const LOGO_BASE_URL = 'https://ae3images.blob.core.windows.net/ncaa-d1/';
