import React from 'react';
import Header from '../../../compounds/Common/Header';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import PlayerStreakFilters from '../../../compounds/PlayerStreakFilters/PlayerStreakFilters';
import ROUTER_URLS from '../../../utils/router/urls';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import { statsFilters, streakRangeOptions, PAGE_TITLE, PAGE_SUBTITLE } from './constants';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import { advancedModalFilterCategories } from '../constants';
import { ContextFilterType } from '../../../types/context';
import NcaaWbbPlayerFiltersLayout from '../../../compounds/ncaawbb/NcaaWbbPlayerFiltersLayout';
import FinderStackLayout from '../../../compounds/FinderStackLayout/FinderStackLayout';
import withCopyableUrl, { WithCopyableUrlProps } from '../../../compounds/CopyableUrlWrapper/CopyableUrlWrapper';
import { appendContextFilters } from '../../../utils/ncaawbb/search';

interface IProps extends WithCopyableUrlProps {};

function PlayerStreakFinderSearch({ handleDone }: IProps) {
  const pageSpecificSearchEnabled = (context: ContextFilterType): boolean =>
    Boolean(context.streakGameNumber) && Number(context.streakGameNumber) > 1;

  const statsFilterPartial = <EliasStatsFilter statsFilterOptions={statsFilters} displayYearInfo={false} />;
  const bottomModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={advancedModalFilterCategories}
      filterType='advancedFilters'
    />
  );

  return (
    <Dashboard>
      <FinderStackLayout
        header={<Header title={PAGE_TITLE} subtitle={PAGE_SUBTITLE} />}
        topPanel={
          <NcaaWbbPlayerFiltersLayout
            handleDone={handleDone}
            statsFilterPartial={statsFilterPartial}
            pageSpecificSearchEnabled={pageSpecificSearchEnabled}
            apiUrls={API_URLS.ncaawbb}
            bottomModalPartial={bottomModalPartial}
          >
            <PlayerStreakFilters streakRangeOptions={streakRangeOptions} />
          </NcaaWbbPlayerFiltersLayout>
        }
      />
    </Dashboard>
  );
}

export default withCopyableUrl(
  PlayerStreakFinderSearch,
  ROUTER_URLS.ncaawbb.playerStreakFinderResults,
  statsFilters,
  appendContextFilters,
);
