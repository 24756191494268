import React, { createContext, useContext, useReducer } from "react";
import { Outlet } from "react-router-dom";

import {
  ContextAdvancedFiltersType,
  ContextFilterType,
  ContextGameTypeFiltersType
} from "../types/context";
import {
  translateNcaamAdvancedFilter,
} from './filter-context-translations';
import {
  defaultAdvancedFilterChips,
  defaultGameTypeChips,
  initialAdvancedFilters,
  initialGameTypeFilters,
  initialFilters,
  initialWnbaFilters,
  ncaaMbbSortOverrides,
} from './filter-context-constants';

export const FilterContext: any = createContext<ContextFilterType | null>(null);
export const FilterDispatchContext: any = createContext(null);

export const useFilterContext = () => useContext(FilterContext);
export const useFilterDispatchContext = () => useContext(FilterDispatchContext);


const filterReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'sortByChanged':
      return {
        ...state,
        sortBy: action.sortBy,
        page: 0,
      };
    case 'pageByChanged':
      return {
        ...state,
        page: action.page,
      };
    case 'selectedStatFiltersChanged': {
      const filters = action.selectedStatsFilters;
      const filterChips = action.chips;
      return {
        ...state,
        searchEnabled: true,
        selectedStatsFilters: filters,
        statsFilterChips: filterChips,
      };
    }
    case 'groupBySelected':
      return {
        ...state,
        groupBy: action.groupBy,
        searchEnabled: action.searchEnabled,
      };
    case 'streakOptionsSelected':
      return {
        ...state,
        streakRangeOption: action.streakRangeOption,
        searchEnabled: true,
      };
    case 'spanOptionSelected':
      return {
        ...state,
        spanRangeOption: action.spanRangeOption,
        searchEnabled: true,
      };
    case 'gameTypeStatusChange':
      return {
        ...state,
        gameTypeStatus: action.gameTypeStatus,
        searchEnabled: true,
      };
    case 'activeStatusChange':
      return {
        ...state,
        activeStatus: action.activeStatus,
        searchEnabled: action.searchEnabled,
      };
    case 'rookieStatusChange':
      return {
        ...state,
        rookieStatus: action.rookieStatus,
        searchEnabled: action.searchEnabled,
      };
    case 'homeAwayChange':
      return {
        ...state,
        homeAway: action.homeAway,
        searchEnabled: action.searchEnabled,
      };
    case 'overtimeStatusChange':
      return {
        ...state,
        overtimeStatus: action.overtimeStatus,
        searchEnabled: action.searchEnabled,
      };
    case 'halfQuarterStatusChange':
      return {
        ...state,
        halfQuarterStatus: action.halfQuarterStatus,
        searchEnabled: action.searchEnabled,
      };
    case 'changeVenueObject':
      return {
        ...state,
        venueObject: action.venueObject,
        searchEnabled: action.searchEnabled,
      };
    case 'changeTeamObject':
      return {
        ...state,
        teamObject: action.teamObject,
        searchEnabled: action.searchEnabled,
      };
    case 'changeConferenceObject':
      return {
        ...state,
        conferenceObject: action.conferenceObject,
        searchEnabled: action.searchEnabled,
      };
    case 'changeOpponentConferenceObject':
      return {
        ...state,
        opponentConferenceObject: action.opponentConferenceObject,
        searchEnabled: action.searchEnabled,
      };
    case 'changeOpponentObject':
      return {
        ...state,
        opponentObject: action.opponentObject,
        searchEnabled: action.searchEnabled,
      };
    case 'changePlayerObject':
      return {
        ...state,
        playerObject: action.playerObject,
        searchEnabled: action.searchEnabled,
      };
    case 'changeOpposingPlayerObject':
      return {
        ...state,
        opposingPlayerObject: action.opposingPlayerObject,
        searchEnabled: action.searchEnabled,
      };
    case 'changePlayerTeammates':
      return {
        ...state,
        playerTeammates: action.playerTeammates,
        searchEnabled: action.searchEnabled,
      };
    case 'changeStreakGameNumber':
      return {
        ...state,
        streakGameNumber: action.streakGameNumber,
        searchEnabled: true,
      };
    case 'changeSpanLength':
      return {
        ...state,
        spanLength: action.spanLength,
        searchEnabled: true,
      };
    case 'changeSearchStatus':
      return {
        ...state,
        searchEnabled: action.searchEnabled,
      };
    case 'changeModalFilter': {
      const filterGroupKey = action.filter.filterGroup;
      // eslint-disable-next-line prefer-destructuring
      const groupTypeFilters = { ...state[filterGroupKey] };
      groupTypeFilters[action.filter.key] = action.filter.value;

      return {
        ...state,
        searchEnabled: true,
        [filterGroupKey]: {
          ...groupTypeFilters,
        }
      }
    }
    case 'changeModalCheckboxWithInput': {
      const filterGroupKey: keyof ContextFilterType = action.filter.filterGroup;
      const filterKey: string = action.filter.key;
      // eslint-disable-next-line prefer-destructuring
      const groupTypeFilters = { ...state[filterGroupKey] };
      const checkboxInput = { ...groupTypeFilters[filterKey] };

      checkboxInput.selected = action.filter.selected;
      checkboxInput.value = action.filter.value;
      checkboxInput.equalityOption = action.filter.equalityOption;
      groupTypeFilters[filterKey] = checkboxInput;

      // AE30-1112: special ordering logic requirements:
      // - if player_age equality is >=, then default sortBy is desc age
      // - else (player_age equality is ==  or <=), then default sortBy is asc age
      let newSortBy = state.sortBy;
      let newSortByField = state.sortByField;
      let newSortByDirection = state.sortByDirection;
      if (state.allowSecondaryDefaultSort && filterKey === 'player_age') {
        if (action.filter.selected || action.filter.value !== '') {
          // Case 1: player_age is selected, set sortBy accordingly
          const desc: string = (action.filter.equalityOption === '>=') ? '-' : '';

          // Note: age is the field that the API uses, player_age is
          // frontend filter name
          newSortBy = `${desc}age,player__display_name`;
          newSortByField = 'age';
          newSortByDirection = desc;
        } else {
          // Case 2: player_age is unselected, but was, reset sortBy accordingly
          newSortBy = '-game__date';
          newSortByField = 'game__date';
          newSortByDirection = '-';
        }
      } else {
        // Case 3: player_age is unselected and was not already selected, do nothing
      }

      return {
        ...state,
        searchEnabled: true,
        [filterGroupKey]: {
          ...groupTypeFilters,
        },
        sortBy: newSortBy,
        sortByField: newSortByField,
        sortByDirection: newSortByDirection,
      }
    }
    case 'clearModalRadioFilter': {
      const filterGroupKey = action.filter.filterGroup;
      const radioGroupKey = action.filter.key;
      const radioGroupInputKey = `${radioGroupKey}_value`;
      const radioGroupEndInputKey = `${radioGroupKey}_end_value`;

      // eslint-disable-next-line prefer-destructuring
      const groupTypeFilters = { ...state[filterGroupKey] };
      groupTypeFilters[radioGroupKey] = initialGameTypeFilters[radioGroupKey as keyof ContextGameTypeFiltersType];
      groupTypeFilters[radioGroupInputKey] = initialGameTypeFilters[radioGroupInputKey as keyof ContextGameTypeFiltersType];
      groupTypeFilters[radioGroupEndInputKey] = initialGameTypeFilters[radioGroupEndInputKey as keyof ContextGameTypeFiltersType];

      return {
        ...state,
        [filterGroupKey]: {
          ...groupTypeFilters
        }
      }
    }
    case 'clearAdvancedFilter': {
      const filterGroupKey = action.filter.filterGroup;
      const inputFilterKey = action.filter.key;

      // eslint-disable-next-line prefer-destructuring
      const groupTypeFilters = { ...state[filterGroupKey] };
      const initialFilter = initialAdvancedFilters[inputFilterKey as keyof ContextAdvancedFiltersType]
      groupTypeFilters[inputFilterKey] = initialFilter;

      // AE30-934: handle deleting chips via the chip close button
      let newSortBy = state.sortBy;
      let newSortByField = state.sortByField;
      let newSortByDirection = state.sortByDirection;
      if (inputFilterKey === 'player_age') {
        // No need to check here for whether the filter is selected,
        // since this handler is explicitly handling removals
        newSortBy = '-game__date';
        newSortByField = 'game__date';
        newSortByDirection = '-';
      }
      return {
        ...state,
        [filterGroupKey]: {
          ...groupTypeFilters
        },
        sortBy: newSortBy,
        sortByField: newSortByField,
        sortByDirection: newSortByDirection,
        searchEnabled: true,
      }
    }
    case 'advancedFiltersChipsUpdate': {
      const filterChips = action.chips;
      return {
        ...state,
        advancedFiltersChips: filterChips,
      };
    }
    case 'gameTypeFiltersChipsUpdate': {
      const filterChips = action.chips;
      return {
        ...state,
        gameTypeFiltersChips: filterChips,
      };
    }
    // TODO: JN, readyToSearch does not appear to be used anywhere yet
    case 'readyToSearch':
      return {
        ...state,
        page: 0,
        sortBy: '-game__date',
        searchEnabled: false,
      };
    case 'gameTypeFiltersReset':
      return {
        ...state,
        gameTypeFilters: {
          ...initialGameTypeFilters,
        },
        gameTypeFiltersChips: defaultGameTypeChips,
      };
    case 'advancedFiltersReset':
      return {
        ...state,
        advancedFilters: {
          ...initialAdvancedFilters,
        },
        advancedFiltersChips: defaultAdvancedFilterChips,
      };
    case 'resetFilters': {
      let filters: ContextFilterType;

      // AE30-1119:  WNBA: fix reset button defaults.
      switch (action.league) {
        case 'wnba':
          filters = initialWnbaFilters;
          break;

        default:
          filters = initialFilters;
      }  
    return {
        ...state,
        ...filters
      };
    }
    // handles loading filters on page refresh and loading a shared URL
    case 'loadFilters': {
      const {
        state: updatedState,
        action: updatedAction
      } = (
        action.translateNcaamFilters ?
        translateNcaamAdvancedFilter(state, action)
        : { state, action }
      );

      return {
        ...updatedState,
        ...updatedAction.apiFilters,
        advancedFilters: {
          // start with initialAdvancedFilters to make sure all fields are
          // present
          ...initialAdvancedFilters,
          // Finally, layer in the actual advancedFilters
          ...updatedAction.apiFilters.advancedFilters,
        },
        gameTypeFilters: {
          ...updatedAction.apiFilters.gameTypeFilters,
        },
        advancedFiltersChips: [
          ...updatedAction.apiFilters.advancedFiltersChips,
        ],
        gameTypeFiltersChips: [
          ...updatedAction.apiFilters.gameTypeFiltersChips,
        ],
      };
    }
    default:
      return state;
  }
};

export function NbaPlayerGameFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      allowSecondaryDefaultSort: true,
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NbaPlayerStreakFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};


export function NbaPlayerSpanFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NbaTeamGameFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NbaTeamStreakFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NbaTeamSpanFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NcaaMbbPlayerGameFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NcaaMbbPlayerStreakFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};


export function NcaaMbbPlayerSpanFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NcaaMbbTeamStreakFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NcaaMbbTeamSpanFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NcaaMbbTeamGameFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NcaaWbbPlayerGameFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NcaaWbbPlayerStreakFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};


export function NcaaWbbPlayerSpanFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NcaaWbbTeamStreakFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NcaaWbbTeamSpanFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NcaaWbbTeamGameFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function WnbaPlayerGameFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialWnbaFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function WnbaPlayerStreakFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialWnbaFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};


export function WnbaPlayerSpanFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialWnbaFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function WnbaTeamGameFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialWnbaFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function WnbaTeamStreakFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialWnbaFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function WnbaTeamSpanFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialWnbaFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};
