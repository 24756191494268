import React from 'react';
import { Box, List } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import StatsIcon from '@mui/icons-material/QueryStats';
import NavListItem from '../../atoms/NavListItem/NavListItem';
import ExpandableNavListItem from '../../atoms/ExpandableNavListItem/ExpandableNavListItem';
import SubNavListItem from '../../atoms/SubNavListItem/SubNavListItem';
import { LeagueType, useLeagueContext } from '../../contexts/LeagueContext';
import ROUTER_URLS from '../../utils/router/urls';


function DrawerLinks() {
  const leagueContext: any = useLeagueContext();
  const league: LeagueType = leagueContext?.selectedLeague?.toLowerCase() || 'nba';

  const routes = ROUTER_URLS[league];

  return (
    <Box overflow='auto' paddingTop={2}>
      <List>
        <NavListItem text="My Dashboard" Icon={<HomeIcon />} path="/dashboard" />
        <ExpandableNavListItem text="Query Tools" Icon={<StatsIcon />}>
          <SubNavListItem text="Player Game Finder" path={routes.playerGameFinderSearch} />
          <SubNavListItem text="Player Streak Finder" path={routes.playerStreakFinderSearch} />
          <SubNavListItem text="Player Span Finder" path={routes.playerSpanFinderSearch} />
          <SubNavListItem text="Team Game Finder" path={routes.teamGameFinderSearch} />
          <SubNavListItem text="Team Streak Finder" path={routes.teamStreakFinderSearch} />
          <SubNavListItem text="Team Span Finder" path={routes.teamSpanFinderSearch} />
        </ExpandableNavListItem>
      </List>
    </Box>
  );
}

export default DrawerLinks;
