import React from 'react';
import PlayerGameTable from '../../../compounds/PlayerGameTable/PlayerGameTable';
import EliasBackButton from '../../../atoms/EliasBackButton/EliasBackButton';
import ROUTER_URLS from '../../../utils/router/urls';
import ResultsLoading from '../../../compounds/ResultsLoading/ResultsLoading';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import { fetchNcaaMbbPlayerGameStats } from '../../../../api/ncaambb';
import { statsFilters, groupByOptions as ncaaGroupByOptions, PAGE_TITLE, PAGE_SUBTITLE } from './constants';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import PlayerGameGroupBy from '../../../compounds/PlayerGameGroupBy/PlayerGameGroupBy';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import { advancedModalFilterCategories } from '../constants';
import { ContextFilterType } from '../../../types/context';
import NcaaMbbPlayerFiltersLayout from '../../../compounds/ncaambb/NcaaMbbPlayerFiltersLayout';
import { mapRows } from '../../../utils/ncaambb/game-finder/map-results';
import { mapColumns } from '../../../utils/ncaambb/game-finder/map-columns';
import withResultsLayout, { WithResultsLayoutProps } from '../../../compounds/ResultsWrapper/ResultsWrapper';
import FinderStackLayout from '../../../compounds/FinderStackLayout/FinderStackLayout';
import { appendContextFilters } from '../../../utils/ncaambb/search';
import Header from '../../../compounds/Common/Header';
import EXTERNAL_URLS from '../../../utils/router/external_urls';
import NcaaMbbGameDataInfo from '../../../compounds/ncaambb/NcaaMbbGameInfo';

interface IProps extends WithResultsLayoutProps {};

function PlayerGameFinderResults({ 
  columnHeaders,
  resultsData,
  tableDataLoading,
  filtersLoading,
  error,
  handleDone,
  pageOrSortDataFetch
}: IProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pageSpecificSearchEnabled = (_context: ContextFilterType): boolean => true;

  const statsFilterPartial = <EliasStatsFilter statsFilterOptions={statsFilters} displayYearInfo={false} />;
  const groupByPartial = <PlayerGameGroupBy
    groupByOptions={ncaaGroupByOptions}
  />
  const bottomModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={advancedModalFilterCategories}
      filterType='advancedFilters'
    />
  );

  return (
    <Dashboard loading={filtersLoading}>
      <FinderStackLayout
        header={
          <Header
            title={PAGE_TITLE}
            subtitle={PAGE_SUBTITLE}
            backButton={
              <EliasBackButton route={ROUTER_URLS.ncaambb.playerGameFinderSearch} />
            }
          />
        }
        topPanel={
          <NcaaMbbPlayerFiltersLayout
            handleDone={handleDone}
            statsFilterPartial={statsFilterPartial}
            pageSpecificSearchEnabled={pageSpecificSearchEnabled}
            groupByPartial={groupByPartial}
            apiUrls={API_URLS.ncaambb}
            bottomModalPartial={bottomModalPartial}
          />
        }
        bottomPanel={
          tableDataLoading || error ?
          <ResultsLoading error={error} /> :
          <PlayerGameTable
            data={resultsData?.results ?? []}
            dataCount={resultsData?.count ?? 0}
            dataMetaInfo={resultsData?.query?.back_to ?? []}
            columnData={columnHeaders || []}
            handleDone={pageOrSortDataFetch}
            linkUrls={EXTERNAL_URLS.NCAAM}
            explanationMessage={ <NcaaMbbGameDataInfo />}
          />
        }
      />
    </Dashboard>
  );
}

export default withResultsLayout(
  PlayerGameFinderResults,
  ROUTER_URLS.ncaambb.playerGameFinderResults,
  statsFilters,
  fetchNcaaMbbPlayerGameStats,
  mapColumns,
  mapRows,
  appendContextFilters,
  true, // translateNcaamFilters
);

