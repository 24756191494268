import * as React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import LogIn from '../../atoms/Auth/LogIn'
import Home from '../../pages/Home';

import NbaPlayerStreakFinderResults from '../../pages/Nba/PlayerStreakFinder/PlayerStreakFinderResults';
import NbaPlayerStreakFinderSearch from '../../pages/Nba/PlayerStreakFinder/PlayerStreakFinderSearch';
import NbaPlayerGameFinderResults from '../../pages/Nba/PlayerGameFinder/PlayerGameFinderResults';
import NbaPlayerGameFinderSearch from '../../pages/Nba/PlayerGameFinder/PlayerGameFinderSearch';
import NbaPlayerSpanFinderSearch from '../../pages/Nba/PlayerSpanFinder/PlayerSpanFinderSearch';
import NbaPlayerSpanFinderResults from '../../pages/Nba/PlayerSpanFinder/PlayerSpanFinderResults';
import NbaTeamGameFinderSearch from '../../pages/Nba/TeamGameFinder/TeamGameFinderSearch';
import NbaTeamGameFinderResults from '../../pages/Nba/TeamGameFinder/TeamGameFinderResults';
import NbaTeamStreakFinderSearch from '../../pages/Nba/TeamStreakFinder/TeamStreakFinderSearch';
import NbaTeamStreakFinderResults from '../../pages/Nba/TeamStreakFinder/TeamStreakFinderResults';
import NbaTeamSpanFinderSearch from '../../pages/Nba/TeamSpanFinder/TeamSpanFinderSearch';
import NbaTeamSpanFinderResults from '../../pages/Nba/TeamSpanFinder/TeamSpanFinderResults';

import NcaaMbbPlayerStreakFinderResults from '../../pages/NcaaMbb/PlayerStreakFinder/PlayerStreakFinderResults';
import NcaaMbbPlayerStreakFinderSearch from '../../pages/NcaaMbb/PlayerStreakFinder/PlayerStreakFinderSearch';
import NcaaMbbPlayerGameFinderResults from '../../pages/NcaaMbb/PlayerGameFinder/PlayerGameFinderResults';
import NcaaMbbPlayerGameFinderSearch from '../../pages/NcaaMbb/PlayerGameFinder/PlayerGameFinderSearch';
import NcaaMbbPlayerSpanFinderSearch from '../../pages/NcaaMbb/PlayerSpanFinder/PlayerSpanFinderSearch';
import NcaaMbbPlayerSpanFinderResults from '../../pages/NcaaMbb/PlayerSpanFinder/PlayerSpanFinderResults';
import NcaaMbbTeamGameFinderResults from '../../pages/NcaaMbb/TeamGameFinder/TeamGameFinderResults';
import NcaaMbbTeamGameFinderSearch from '../../pages/NcaaMbb/TeamGameFinder/TeamGameFinderSearch';
import NcaaMbbTeamSpanFinderResults from '../../pages/NcaaMbb/TeamSpanFinder/TeamSpanFinderResults';
import NcaaMbbTeamSpanFinderSearch from '../../pages/NcaaMbb/TeamSpanFinder/TeamSpanFinderSearch';
import NcaaMbbTeamStreakFinderResults from '../../pages/NcaaMbb/TeamStreakFinder/TeamStreakFinderResults';
import NcaaMbbTeamStreakFinderSearch from '../../pages/NcaaMbb/TeamStreakFinder/TeamStreakFinderSearch';
import NcaaMbbTeam from '../../pages/NcaaMbb/Team/Team';
import NcaaMbbPlayer from '../../pages/NcaaMbb/Player/Player';
import NcaaMbbGame from '../../pages/NcaaMbb/Game/Game';
import NcaaMbbGameDataInfoPage from '../../pages/NcaaMbb/GameDataInfo/NcaaMbbGameDataInfoPage';
import NcaaMbbTeamDataInfoPage from '../../pages/NcaaMbb/TeamDataInfo/NcaaMbbTeamDataInfoPage';

import WnbaPlayerStreakFinderResults from '../../pages/Wnba/PlayerStreakFinder/PlayerStreakFinderResults';
import WnbaPlayerStreakFinderSearch from '../../pages/Wnba/PlayerStreakFinder/PlayerStreakFinderSearch';
import WnbaPlayerGameFinderResults from '../../pages/Wnba/PlayerGameFinder/PlayerGameFinderResults';
import WnbaPlayerGameFinderSearch from '../../pages/Wnba/PlayerGameFinder/PlayerGameFinderSearch';
import WnbaPlayerSpanFinderSearch from '../../pages/Wnba/PlayerSpanFinder/PlayerSpanFinderSearch';
import WnbaPlayerSpanFinderResults from '../../pages/Wnba/PlayerSpanFinder/PlayerSpanFinderResults';
import WnbaTeamGameFinderSearch from '../../pages/Wnba/TeamGameFinder/TeamGameFinderSearch';
import WnbaTeamGameFinderResults from '../../pages/Wnba/TeamGameFinder/TeamGameFinderResults';
import WnbaTeamStreakFinderSearch from '../../pages/Wnba/TeamStreakFinder/TeamStreakFinderSearch';
import WnbaTeamStreakFinderResults from '../../pages/Wnba/TeamStreakFinder/TeamStreakFinderResults';
import WnbaTeamSpanFinderSearch from '../../pages/Wnba/TeamSpanFinder/TeamSpanFinderSearch';
import WnbaTeamSpanFinderResults from '../../pages/Wnba/TeamSpanFinder/TeamSpanFinderResults';

import NCAAW_ROUTES from './NcaaWRoutes';

import ROUTER_URLS from './urls';
import PrivateRoute from './PrivateRoute';
import {
  NbaPlayerGameFinderContextProvider,
  NbaPlayerSpanFinderContextProvider,
  NbaPlayerStreakFinderContextProvider,
  NbaTeamGameFinderContextProvider,
  NbaTeamSpanFinderContextProvider,
  NbaTeamStreakFinderContextProvider,

  NcaaMbbPlayerGameFinderContextProvider,
  NcaaMbbPlayerSpanFinderContextProvider,
  NcaaMbbPlayerStreakFinderContextProvider,
  NcaaMbbTeamGameFinderContextProvider,
  NcaaMbbTeamSpanFinderContextProvider,
  NcaaMbbTeamStreakFinderContextProvider,

  WnbaPlayerGameFinderContextProvider,
  WnbaPlayerSpanFinderContextProvider,
  WnbaPlayerStreakFinderContextProvider,
  WnbaTeamGameFinderContextProvider,
  WnbaTeamSpanFinderContextProvider,
  WnbaTeamStreakFinderContextProvider,
} from '../../contexts/FilterContext';
import ChangePassword from '../../pages/User/ChangePassword/ChangePassword';
import UserDashboard from '../../pages/User/Dashboard/Dashboard';
import PageTitle from '../../atoms/PageTitle/PageTitle';
import { PAGE_TITLE as NCAAM_PLAYER_GAME_TITLE } from '../../pages/NcaaMbb/PlayerGameFinder/constants';
import { PAGE_TITLE as NCAAM_PLAYER_STREAK_TITLE } from '../../pages/NcaaMbb/PlayerStreakFinder/constants';
import { PAGE_TITLE as NCAAM_PLAYER_SPAN_TITLE } from '../../pages/NcaaMbb/PlayerSpanFinder/constants';
import { PAGE_TITLE as NCAAM_TEAM_GAME_TITLE } from '../../pages/NcaaMbb/TeamGameFinder/constants';
import { PAGE_TITLE as NCAAM_TEAM_STREAK_TITLE } from '../../pages/NcaaMbb/TeamStreakFinder/constants';
import { PAGE_TITLE as NCAAM_TEAM_SPAN_TITLE } from '../../pages/NcaaMbb/TeamSpanFinder/constants';


export const ROUTES_CONFIG = [
  {
    path: ROUTER_URLS.login,
    element: <LogIn />
  },
  {
    path: ROUTER_URLS.home,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <PageTitle title='AccessElias' />
        <Home />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTER_URLS.dashboard,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <PageTitle title='AccessElias - Home' />
        <UserDashboard />
      </PrivateRoute>
    )
  },
  {
    path: ROUTER_URLS.changePassword,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <PageTitle title='AccessElias - Change Password' />
        <ChangePassword />
      </PrivateRoute>
    )
  },
  {
    element: <NbaPlayerGameFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.nba.playerGameFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='NBA Player Game Finder' />
            <NbaPlayerGameFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.nba.playerGameFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='NBA Player Game Finder' />
            <NbaPlayerGameFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NbaPlayerStreakFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.nba.playerStreakFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='NBA Player Streak Finder' />
            <NbaPlayerStreakFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.nba.playerStreakFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='NBA Player Streak Finder' />
            <NbaPlayerStreakFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NbaPlayerSpanFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.nba.playerSpanFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='NBA Player Span Finder' />
            <NbaPlayerSpanFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.nba.playerSpanFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='NBA Player Span Finder' />
            <NbaPlayerSpanFinderResults />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    element: <NbaTeamGameFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.nba.teamGameFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='NBA Team Game Finder' />
            <NbaTeamGameFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.nba.teamGameFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='NBA Team Game Finder' />
            <NbaTeamGameFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NbaTeamStreakFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.nba.teamStreakFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='NBA Team Streak Finder' />
            <NbaTeamStreakFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.nba.teamStreakFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='NBA Team Streak Finder' />
            <NbaTeamStreakFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NbaTeamSpanFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.nba.teamSpanFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='NBA Team Span Finder' />
            <NbaTeamSpanFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.nba.teamSpanFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='NBA Team Span Finder' />
            <NbaTeamSpanFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NcaaMbbPlayerGameFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.ncaambb.playerGameFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAM_PLAYER_GAME_TITLE} />
            <NcaaMbbPlayerGameFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.ncaambb.playerGameFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAM_PLAYER_GAME_TITLE} />
            <NcaaMbbPlayerGameFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NcaaMbbPlayerStreakFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.ncaambb.playerStreakFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAM_PLAYER_STREAK_TITLE} />
            <NcaaMbbPlayerStreakFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.ncaambb.playerStreakFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAM_PLAYER_STREAK_TITLE} />
            <NcaaMbbPlayerStreakFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NcaaMbbPlayerSpanFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.ncaambb.playerSpanFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAM_PLAYER_SPAN_TITLE} />
            <NcaaMbbPlayerSpanFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.ncaambb.playerSpanFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAM_PLAYER_SPAN_TITLE} />
            <NcaaMbbPlayerSpanFinderResults />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    element: <NcaaMbbTeamGameFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.ncaambb.teamGameFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAM_TEAM_GAME_TITLE} />
            <NcaaMbbTeamGameFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.ncaambb.teamGameFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAM_TEAM_GAME_TITLE} />
            <NcaaMbbTeamGameFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NcaaMbbTeamSpanFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.ncaambb.teamSpanFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAM_TEAM_SPAN_TITLE} />
            <NcaaMbbTeamSpanFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.ncaambb.teamSpanFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAM_TEAM_SPAN_TITLE} />
            <NcaaMbbTeamSpanFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NcaaMbbTeamStreakFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.ncaambb.teamStreakFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAM_TEAM_STREAK_TITLE} />
            <NcaaMbbTeamStreakFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.ncaambb.teamStreakFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAM_TEAM_STREAK_TITLE} />
            <NcaaMbbTeamStreakFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    path: ROUTER_URLS.ncaambb.team,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <NcaaMbbTeam />
      </PrivateRoute>
    )
  },
  {
    path: ROUTER_URLS.ncaambb.player,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <NcaaMbbPlayer />
      </PrivateRoute>
    )
  },
  {
    path: ROUTER_URLS.ncaambb.game,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <NcaaMbbGame />
      </PrivateRoute>
    )
  },
  {
    path: ROUTER_URLS.ncaambb.dataInfo,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <PageTitle title="NCAA Men's Player Data Info" />
        <NcaaMbbGameDataInfoPage />
      </PrivateRoute>
    )
  },
  {
    path: ROUTER_URLS.ncaambb.teamDataInfo,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <PageTitle title="NCAA Men's Team Data Info" />
        <NcaaMbbTeamDataInfoPage />
      </PrivateRoute>
    )
  },
  {
    element: <WnbaPlayerGameFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.wnba.playerGameFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='WNBA Player Game Finder' />
            <WnbaPlayerGameFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.wnba.playerGameFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='WNBA Player Game Finder' />
            <WnbaPlayerGameFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <WnbaPlayerStreakFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.wnba.playerStreakFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='WNBA Player Streak Finder' />
            <WnbaPlayerStreakFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.wnba.playerStreakFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='WNBA Player Streak Finder' />
            <WnbaPlayerStreakFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <WnbaPlayerSpanFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.wnba.playerSpanFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='WNBA Player Span Finder' />
            <WnbaPlayerSpanFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.wnba.playerSpanFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='WNBA Player Span Finder' />
            <WnbaPlayerSpanFinderResults />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    element: <WnbaTeamGameFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.wnba.teamGameFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='WNBA Team Game Finder' />
            <WnbaTeamGameFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.wnba.teamGameFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='WNBA Team Game Finder' />
            <WnbaTeamGameFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <WnbaTeamStreakFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.wnba.teamStreakFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='WNBA Team Streak Finder' />
            <WnbaTeamStreakFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.wnba.teamStreakFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='WNBA Team Streak Finder' />
            <WnbaTeamStreakFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <WnbaTeamSpanFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.wnba.teamSpanFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='WNBA Team Span Finder' />
            <WnbaTeamSpanFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.wnba.teamSpanFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title='WNBA Team Span Finder' />
            <WnbaTeamSpanFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  ...NCAAW_ROUTES,
];

export const ROUTES = createBrowserRouter(ROUTES_CONFIG);
